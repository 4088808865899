@import '../../assets/base_css/_animations.css';

.video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .videoItem {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-color: var(--color_detail-tabsWrapper-bg);

    &--fadeIn {
      @mixin longFadeInAnimation;
    }

    &--fadeOut {
      @mixin longFadeOutAnimation;
    }

    &--hidden {
      visibility: hidden;
    }
  }
}
