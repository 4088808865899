.buttonInline {
  margin: 0.9375rem 0.8125rem 0.625rem;
  cursor: pointer;

  @mixin tvDevice {
    margin: 1.5rem 4.8125rem 1.625rem;
  }

  @media (--mq-from-tablet) {
    margin: 0.5rem 1.875rem 1.4375rem;
  }

  @media (--mq-from-desktop) {
    margin: 0.5rem 3.75rem 1.4375rem;
  }
}
