.catchPhrase {
  display: flex;
  justify-content: center;
  margin: 0 0.8125rem;

  &__text {
    max-width: var(--max-width-text);
    margin: 0.75rem 0 0.625rem 0;
    font-family: var(--font_hind);
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.25;
    color: var(--color_white);
    text-align: center;

    @media (--mq-from-tablet) {
      font-size: 2rem;
    }

    @media screen and (min-width: 375px) {
      margin-bottom: 0.75rem;
    }
  }

  &__logo {
    width: 11.25rem;
    vertical-align: baseline;
  }
}
