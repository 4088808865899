@import '../../../../../assets/base_css/_animations.css';

.offerHighlight__infoLayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: var(--OH-layout-padding-vertical) var(--OH-layout-padding-horizontal)
    var(--OH-layout-padding-bottom);

  @mixin tvDevice {
    padding: var(--OH-layout-padding-vertical-tv)
      var(--OH-layout-padding-horizontal-tv);
  }
}

.offerHighlight__infoLayer--noPictureOrVideo {
  position: relative;
  padding-top: 2rem;
  margin-top: var(--height_header-mobile);

  @media (--mq-from-tablet) {
    margin-top: var(--header-height);
  }
}

.offerHighlight__infoWrap {
  position: relative;
  z-index: 2;
  width: 100%;

  @mixin tvDevice {
    min-height: 18.75rem;
    margin-bottom: 2.875rem;
  }
}

.offerHighlight__videoButton {
  position: absolute;
  right: 0;

  button {
    display: flex;
    justify-content: center;
  }
}

.offerHighlight__logo {
  height: 3.125rem;
  margin-bottom: 1.0625rem;
}

.offerHighlight__title {
  display: inline-block;
  width: var(--OH-main-text-width);
  margin: var(--OH-title-margin);
  font-family: var(--font_canal);
  font-size: var(--OH-title-font-size);
  line-height: 1.14;
  text-transform: uppercase;
}

.offerHighlight__description {
  width: var(--OH-main-text-width);
  margin: 0;
  font-size: var(--OH-description-font-size);
  color: var(--color_showcase-description-fg);
}

.offerHighlight__actionsWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--OH-actions-wrap-margin);
}

.offerHighlight__button {
  margin-right: 1.9375rem;
}

.offerHighlight__credit {
  position: absolute;
  bottom: 0.625rem;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  color: var(--color_white);
  white-space: nowrap;
  opacity: 0.3 !important;

  @media (--mq-from-desktop) {
    right: calc(var(--OH-controlVideoButton-width) + 1.125rem);
    bottom: 0.8125rem;
  }
}

.offerHighlight__credit--hidden {
  visibility: hidden;
}

.offerHighlight__credit--show {
  @mixin fadeInAnimation;
}
