.subscribeCTA {
  margin: 0 0.8125rem;
  font-size: 1.0625rem;
  text-align: center;

  &__button {
    width: 17.5625rem;
    padding: 0.8125rem 1.25rem 0.875rem;
    font-family: var(--font_hind);
    font-weight: 600;
  }

  &__button_primary {
    background-color: var(--color_showcase-button-primary-bg);

    &:hover {
      background-color: var(--color_showcase-button-primary-hover-bg);
    }
  }

  &__button_secondary {
    background-color: var(--color_showcase-button-secondary);

    &:hover {
      background-color: var(--color_showcase-button-secondary-hover);
    }
  }

  &__button_default {
    background-color: currentColor;
  }

  &__description {
    max-width: var(--max-width-text-mobile);
    margin: 0.625rem auto 0.9375rem;
    font-size: 0.875rem;
    line-height: 1.57;
    color: var(--color_grey-300);
  }

  @media (--mq-from-tablet) {
    &__button {
      width: 20rem;
    }

    &__description {
      max-width: var(--max-width-text);
      margin: 1.25rem auto 1.875rem;
      font-size: 1rem;
    }
  }
}
