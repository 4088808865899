.offerHighlight {
  --OH-height: 75vh;
  --OH-layout-padding-vertical: 0.125rem;
  --OH-layout-padding-vertical-tv: 2.875rem;
  --OH-layout-padding-horizontal: var(--showcase-side-padding-mobile);
  --OH-layout-padding-horizontal-tv: 5.625rem;
  --OH-layout-padding-bottom: 0.125rem;
  --OH-main-text-width: 100%;
  --OH-title-margin: 0 0 0.3125rem;
  --OH-title-font-size: 1.5rem;
  --OH-description-font-size: 0.875rem;
  --OH-button-size-base: 2.75rem;
  --OH-actions-wrap-margin: 0.9375rem 0 2rem;
  --OH-controlVideoButton-width: 2rem;

  @media (--mq-from-tablet) {
    --OH-height: 85vh;
    --OH-layout-padding-vertical: 2.5rem;
    --OH-layout-padding-horizontal: var(--showcase-side-padding-tablet);
    --OH-layout-padding-bottom: 0.125rem;
    --OH-title-margin: 0 0 1rem;
    --OH-title-font-size: 1.875rem;
    --OH-description-font-size: 1rem;
    --OH-actions-wrap-margin: 1.875rem 0 2rem;
  }

  @media (--mq-from-laptop) {
    --OH-layout-padding-vertical: 1.875rem;
    --OH-layout-padding-bottom: 0.125rem;
    --OH-main-text-width: 50%;
    --OH-button-size-base: 2.75rem;
  }

  @media (--mq-from-desktop) {
    --OH-height: auto;
    --OH-layout-padding-vertical: 3.75rem;
    --OH-layout-padding-horizontal: var(--showcase-side-padding-desktop);
    --OH-layout-padding-bottom: 3.75rem;
    --OH-actions-wrap-margin: 1.875rem 0 0;
  }

  @mixin tvDevice {
    --OH-title-font-size: 2.5rem;
    --OH-description-font-size: 1.9375rem;
  }

  position: relative;
  width: 100%;
  height: var(--OH-height);

  @media (--mq-from-desktop) {
    @mixin aspectRatioLegacy 16, 7;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      transparent 60%
    );
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto !important;
    min-width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);

    @media (--mq-from-tablet) {
      width: 100%;
      height: auto !important;
      min-height: 100%;
    }

    @media (--mq-from-desktop) {
      transform: translateX(-50%) translateY(-40%);
    }
  }
}

.offerHighlight__noPictureOrVideo {
  height: auto;
  padding-top: 0;
}

:global {
  .offerHighlightFocus .tv__focus.tv__focus {
    @mixin tv-focus-button;
  }
}
