.showcaseText {
  &__subtitle {
    margin: 0.9375rem 0.8125rem 0.625rem;
    font-size: 0.875rem;
    color: var(--color_white);
    opacity: 0.7;
  }

  &__paragraph {
    margin: 0 0.8125rem;
    font-size: 0.75rem;
    color: var(--color_showcase-paragraph-text);
    text-align: justify;
  }

  &__title2 {
    margin: 0 auto;
    font-family: var(--font_hind);
    font-size: 1.0625rem;
    font-weight: 600;
    color: var(--color_white);
  }

  @media (--mq-from-tablet) {
    &__paragraph {
      margin: 1.25rem 1.875rem;
    }

    &__subtitle {
      margin: 0.5rem 1.875rem 1.4375rem;
      font-size: 1rem;
    }
  }

  @media (--mq-from-desktop) {
    &__paragraph {
      margin: 1.25rem 3.75rem;
    }

    &__subtitle {
      margin: 0.5rem 3.75rem 1.4375rem;
      font-size: 1rem;
    }
  }
}
