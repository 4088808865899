.container {
  width: 100%;
  height: 100%;

  .indicator {
    position: absolute;
    top: 3%;
    left: 1.8%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.7rem 0.5rem 0.5rem;
    font-size: 1.875rem;
    color: var(--color_white);
    background-color: rgba(0, 0, 0, 0.3);

    span {
      height: 2.3rem;
    }

    svg {
      width: 2.1875rem;
      height: 2.1875rem;
      fill: var(--color_white);
      transform: rotate(90deg);
    }
  }

  .player {
    width: 230%;
    height: 100%;
    margin-left: -65%;

    /* To hide the black stripes by scaling up the video */
    &--scale-up-small {
      /* Used by promotion cover preview */
      transform: scale(1.145);
    }
    &--scale-up-medium {
      /* Anticipated value, it can be changed */
      transform: scale(1.3);
    }
    &--scale-up-large {
      /* Used by trailer preview */
      transform: scale(1.4);
    }

    @media (--mq-from-tablet) {
      width: 100%;
      margin: 0;
    }
  }

  .controls {
    @mixin absolutePosition;
    z-index: 1;
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-10);
    width: 100vw;
    height: 100%; /* do not use vh because of the bottom tab bar of mobiles */
    background-color: var(--background-page);

    .player {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  &__errorTemplate {
    @mixin absolutePosition;
    background-color: var(--background-page);
  }
}
