.showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: var(--color_showcase-fg);
  background-color: var(--color_showcase-background);

  &--withHeader {
    margin-top: calc(var(--size_mobileHeader_height) * -1);

    @media (--mq-from-laptop) {
      margin-top: calc(var(--size_header_height) * -1);
    }

    @mixin tvDevice {
      margin-top: calc(var(--height_header-tv) * -1);
    }
  }

  &__strates {
    position: relative;
    z-index: 3;
    width: 100%;
  }

  &__focusStyle {
    text-decoration: none;
  }
}
