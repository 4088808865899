@import '../../../../assets/base_css/_animations.css';

.actions {
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &--fadeIn {
    @mixin fadeInAnimation;
  }

  @media (--mq-from-tablet) {
    flex-direction: row;
  }

  .item {
    margin: 0 0 0.5rem 0;

    @media (--mq-from-tablet) {
      margin: 0 0.5rem 0 0;
    }

    &:last-child {
      margin: 0;
    }
  }
}
